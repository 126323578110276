import React from 'react';

import { FileDriver } from '.';

const BackupDriver: FileDriver = (props) => {
  if (props.fileType) {
    return <div>No preview driver for {props.fileType}.</div>;
  }

  return (
    <div>
      Could not get file type for {props.filePath}. No preview available.
    </div>
  );
};

export default BackupDriver;
